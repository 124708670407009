<template>
  <section class="xbts-deposit">
    <div class="card w-100">
      <div class="card-body" v-if="protocol.type === 'core'">
        <div class="row">
          <div class="col-md-12">
            <span class="x-crumbs"><router-link to="/">XBTS</router-link> <i class="mdi mdi-chevron-right"></i> <router-link
                to="/wallet">{{ $t('wallet.wallet') }}</router-link> <i
                class="mdi mdi-chevron-right"></i> <span>{{ $t('wallet.deposit') }}</span></span>
          </div>
        </div>
        <hr>
        <div class="row">
        <div class="col-md-6">
          <SelectCryptoDeposit/>
        </div>
        <div class="col-md-6">
          {{ $t('wallet.deposit_network') }} BitShares Blockchain
        </div>
        </div>
        <div class="row" v-if="this.$root.account.account.name">
          <div class="col-md-6">
            <table class="table table-borderless">
              <tbody>
              <tr>
                <td>{{ $t('wallet.total') }}</td>
                <td>
                  <span
                      class="text-white mr-1">{{ formatBalance((balanceBts.amount * 1 + balanceBts.limit_orders * 1) / 10 ** balanceBts.precision, balanceBts.precision) }}</span>
                  <span>BTS</span>
                </td>
              </tr>
              <tr>
                <td>{{ $t('wallet.order') }}</td>
                <td>
                  <span
                      class="text-white mr-1">{{ formatBalance((balanceBts.limit_orders) / 10 ** balanceBts.precision, balanceBts.precision) }}</span>
                  <span>BTS</span>
                </td>
              </tr>
              <tr>
                <td>{{ $t('wallet.available') }}</td>
                <td>
                                    <span class="text-white mr-1">
                                        {{ formatBalance((balanceBts.amount) / 10 ** balanceBts.precision, balanceBts.precision) }}
                                    </span>
                  <span>BTS</span>
                </td>
              </tr>
              <tr>
                <td>{{ $t('app.support') }}</td>
                <td><a href="https://t.me/xbtsio" target="_blank">https://t.me/xbtsio</a></td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-6 text-center">
            <p>BTS Account</p>
            <p>
              <QrCode :options="{size:120}" :value="this.$root.account.account.name" class="qr-wallet"/>
            </p>
            <span class="text-white">
                            {{ this.$root.account.account.name }}<i @click="doCopyText($root.account.account.name)"
                                                                    title="Copy Address" v-b-tooltip.html.bottom
                                                                    class="ml-2 icon-docs text-warning cursor-pointer"></i>
                        </span>
          </div>

          <div v-if="this.$i18n.locale === 'ru'" class="text-danger border border-dark p-1">
            <i class="icon-bulb"></i>
            Пожалуйста будьте внимательны, ваш адрес для пополнения BTS BitShares
            <a norefferer target="_blank" :href="'https://bts.ai/details/account/'+ $root.account.account.name">{{$root.account.account.name}}</a> является публичным,
            никогда не переводите BTS с других бирж по рекомендации кого либо на адреса похожие на ваш аккаунт.
            Блокчейн BitShares публичный и мошенники могут видеть ваш адрес в обозревателе блоков и создавать адреса немного
            отличающиеся от имени вашего аккаунта и просить перевести BTS на него.
            Вы не сможете вернуть свои BTS, если отправите депозит на чужой адрес. Ваш адрес для депозита BTS: <strong class="text-white">{{$root.account.account.name}}</strong>
            и только на него вы можете пополнить BTS, любые другие адреса для пополнения BTS могут принадлежать мошенникам.
          </div>

          <div v-if="this.$i18n.locale !== 'ru'" class="text-danger border border-dark p-1">
            <i class="icon-bulb"></i>
            Please be careful, your address for deposit BTS BitShares
            <a norefferer target="_blank" :href="'https://bts.ai/details/account/'+ $root.account.account.name">{{$root.account.account.name}}</a> is public,
            and only on it address you can deposit BTS, any other addresses for deposit BTS may belong to scammers.
            Never transfer BTS from other exchanges on the recommendation of someone to addresses similar to your account.
          </div>
        </div>
      </div>
      <div class="card-body" v-if="gateways[protocol.type]">
        <div class="row">
          <div class="col-md-12">
            <span class="x-crumbs"><router-link to="/">XBTS</router-link> <i class="mdi mdi-chevron-right"></i> <router-link
                to="/wallet">{{ $t('wallet.wallet') }}</router-link> <i
                class="mdi mdi-chevron-right"></i> <span>{{ $t('wallet.deposit') }}</span></span>
          </div>
        </div>

        <hr/>
        <div class="row">
          <div class="col-md-6 text-left">
            <button :class="protocol.type === 'coins' ? 'btn-primary' : 'btn-outline-primary'"
                    @click="setProtocol('coins')" class="ml-2 btn btn-sm"
                    title="Native Blockchain" v-if="gateways.coins[symbol]">
              COIN
            </button>
            <button :class="protocol.type === 'eth' ? 'btn-primary' : 'btn-outline-primary'" @click="setProtocol('eth')"
                    class="ml-2 btn btn-sm"
                    title="Ethereum network [ETH]" v-if="gateways.eth[symbol]">ETH
            </button>
            <button :class="protocol.type === 'bsc' ? 'btn-primary' : 'btn-outline-primary'" @click="setProtocol('bsc')"
                    class="ml-2 btn btn-sm"
                    title="Binance Smart Chain [BSC] BEP20" v-if="gateways.bsc[symbol]">BSC
            </button>
            <button :class="protocol.type === 'eos' ? 'btn-primary' : 'btn-outline-primary'"
                    @click="setProtocol('eos')" class="ml-2 btn btn-sm"
                    title="EOS Chain" v-if="gateways.eos[symbol]">EOS
            </button>
            <button :class="protocol.type === 'waves' ? 'btn-primary' : 'btn-outline-primary'"
                    @click="setProtocol('waves')" class="ml-2 btn btn-sm"
                    title="WAVES Platform [WAVES]" v-if="gateways.waves[symbol]">
              WAVES
            </button>
          </div>
          <div class="col-md-6 text-right text-small">
            <div v-if="gateways[protocol.type][symbol].contract">
              Contract
              <a :href="'https://etherscan.io/token/' + gateways[protocol.type][symbol].contract"
                 target="_blank"
                 v-if="protocol.type === 'eth'">{{ gateways[protocol.type][symbol].contract }}</a>
              <a :href="'https://bscscan.com/token/' + gateways[protocol.type][symbol].contract"
                 target="_blank"
                 v-if="protocol.type === 'bsc'">{{ gateways[protocol.type][symbol].contract }}</a>
            </div>

            <div v-if="gateways[protocol.type][symbol].id">
              Asset Id
              <a :href="'https://wavesexplorer.com/assets/' + gateways[protocol.type][symbol].id"
                 target="_blank"
                 v-if="protocol.type === 'waves'">{{ gateways[protocol.type][symbol].id }}</a>
              <a :href="'https://dev.pywaves.org/assets/' + gateways[protocol.type][symbol].id"
                 target="_blank"
                 v-if="protocol.type === 'waves'"><i
                  class="ml-3 icon-share-alt"></i></a>
            </div>
          </div>
        </div>

        <hr/>
        <div class="row">
          <div class="col-md-6">
            <!--
            <span class="ml-1 wallet-icon">{{ gateways[protocol.type][symbol]['name'] }} <img
                :src="'images/assets/' + gateways[protocol.type][symbol]['backingCoin'].toLowerCase() + '.png'"/></span>
                -->

            <SelectCryptoDeposit/>
          </div>
          <div class="col-md-6">
            {{ $t('wallet.deposit_network') }} <span class="text-white">{{ protocol.title }}</span>
            {{ gateways[protocol.type][symbol]['name'] }}

          </div>
        </div>

        <!--<hr/>-->

        <div class="row">
          <div class="col-md-6">
            <table class="table table-borderless">
              <tbody>
              <tr>
                <td>{{ $t('wallet.total') }}</td>
                <td>
                  <span
                      class="text-white mr-1">{{ formatBalance((balance.amount * 1 + balance.limit_orders * 1) / 10 ** balance.precision, balance.precision) }}</span>
                  <span>{{ balance.symbol.replace('XBTSX.', '') }}</span>
                </td>
              </tr>
              <tr>
                <td>{{ $t('wallet.order') }}</td>
                <td>
                  <span
                      class="text-white mr-1">{{ ((balance.limit_orders) / 10 ** balance.precision).toFixed(balance.precision) }}</span>
                  <span>{{ balance.symbol.replace('XBTSX.', '') }}</span>
                </td>
              </tr>
              <tr>
                <td>{{ $t('wallet.available') }}</td>
                <td>
                                    <span class="text-success cursor-pointer mr-1">
                                        {{ formatBalance((balance.amount) / 10 ** balance.precision, balance.precision) }}
                                    </span>
                  <span>{{ balance.symbol.replace('XBTSX.', '') }}</span>
                </td>
              </tr>
              <tr>
                <td>{{ $t('app.support') }}</td>
                <td><a href="https://t.me/xbtsio" target="_blank">https://t.me/xbtsio</a></td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-6 text-center" v-if="!address">
            <div class="w-100">
              <atom-spinner
                  class="text-center"
                  :animation-duration="1000"
                  :size="120"
                  :color="'#248afd'"
              />
            </div>
          </div>


          <div class="col-md-6" v-if="gateways[protocol.type][symbol]['depositAllowed']">

            <div class="row" v-if="address">
              <div :class="address.inputMemo ? 'col-md-6 mb-3' : 'col-md-12'" class="text-center">
                <p>{{ $t('wallet.address') }}</p>
                <p v-if="address.inputAddress">
                  <QrCode :options="{size:120}" :value="address.inputAddress" class="qr-wallet"/>
                </p>
                <div class="text-white border p-2 w-100">
                                    <span class="font-weight-light">{{ address.inputAddress }}</span><i
                    @click="doCopyText(address.inputAddress)" title="Copy Address" v-b-tooltip.html.bottom
                    class="ml-2 icon-docs text-warning cursor-pointer"></i>
                                </div>
              </div>
              <div class="col-md-6 text-center" v-if="address.inputMemo">
                <p>
                  {{ gateways[protocol.type][symbol]['backingCoin'] }} {{ $t('wallet.memo') }} <i class="icon-question"
                                                                                                  title="Memo, comment"
                                                                                                  v-b-tooltip.html.top></i>
                </p>
                <p>
                  <QrCode :options="{size:120}" :value="address.inputMemo" class="qr-wallet"/>
                </p>
                <div class="text-white border p-2 w-100">
                                    <span class="font-weight-light">{{ address.inputMemo }}</span><i
                    @click="doCopyText(address.inputMemo)" title="Copy Memo" v-b-tooltip.html.top
                    class="ml-2 icon-docs text-warning cursor-pointer"></i>
                                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6" v-if="!gateways[protocol.type][symbol]['depositAllowed']">
            <b-alert class="small" show variant="warning">
              <i class="icon-bulb"></i> {{ $t('wallet.dep_maintenance') }}
            </b-alert>
          </div>
        </div>


        <hr/>
        <div class="row">
          <div class="col-md-6">
            <span class="text-danger">{{
                $t('wallet.to_dep', {
                  asset: gateways[protocol.type][symbol]['backingCoin'],
                  network: protocol.title + ' (' + gateways[protocol.type][symbol]['name'] + ')'
                })
              }}</span>
            <br>
            <span class="small">
                            {{ $t('wallet.warn_dep') }}
                        </span>
          </div>
          <div class="col-md-6">
                        <span class="mt-3 text-warning small">
                            <i class="icon-bulb"></i> {{
                            $t('wallet.min_deposit',
                                {
                                  amount: (gateways[protocol.type][symbol].minAmount / 10 ** gateways[protocol.type][symbol].precision).toFixed(gateways[protocol.type][symbol].precision),
                                  asset: gateways[protocol.type][symbol]['backingCoin']
                                })
                          }}
                        </span>

            <br/><span v-show="address.inputMemo" class="mt-3 text-warning small"><i class="icon-bulb"></i> {{$t('wallet.req_memo')}}</span>
          </div>
        </div>
        <hr/>
      </div>
    </div>
  </section>
</template>

<script>
import QrCode from '@/util/QRCode';
import Vue from "vue";
import VueClipboard from "vue-clipboard2";
import SelectCryptoDeposit from '@/components/wallet/SelectCryptoDeposit';

VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard);
import {AtomSpinner} from 'epic-spinners';
import eventBus from "@/plugins/event-bus";

export default {
  name: "WalletDeposit",
  components: {
    QrCode,
    AtomSpinner,
    SelectCryptoDeposit,
  },
  data() {
    return {
      address: null,
      protocol: {
        type: null,
        title: null,
      },
      symbol: null,
      breadcrumbs: [
        {
          text: "XBTS",
          href: "/#/"
        },
        {
          text: "Wallet",
          href: "#/wallet"
        },
        {
          text: "Deposit",
          active: true
        },
      ],
    }
  },
  methods: {

    async getAddress(type) {
      this.address = '';
      this.address = await this.$store.dispatch('wallet/getAddress', {
        gate: type,
        coin: this.gateways[this.protocol.type][this.symbol]['walletType'],
        data: {
          inputCoinType: this.gateways[this.protocol.type][this.symbol]['backingCoin'].toLowerCase(),
          outputCoinType: this.symbol.toLowerCase(),
          outputAddress: this.$root.account.account.name
        }
      })
    },
    async setProtocol(type) {
      this.protocol.type = type;
      if (type !== 'core') {
        await this.getAddress(type);
      }
      if (type === 'coins') {
        this.protocol.title = 'Native Blockchain';
      }
      if (type === 'eth') {
        this.protocol.title = 'Ethereum network';

      }
      if (type === 'eos') {
        this.protocol.title = 'EOS Chain';
      }
      if (type === 'waves') {
        this.protocol.title = 'WAVES Platform';
      }
      if (type === 'bsc') {
        this.protocol.title = 'Binance Smart Chain [BSC]';
      }
    },
    async defaultProtocol(asset) {
      this.address = '';
      if (asset === 'BTS') {
        await this.setProtocol('core');
        await eventBus.emit('set:asset:bts-deposit');

        return;
      }
      if (this.gateways.coins[asset]) {
        await this.setProtocol('coins');
        await eventBus.emit('set:asset', {
          asset: asset,
          protocol: 'coins',
          op: 'deposit'
        });
        return;
      }
      if (this.gateways.eth[asset]) {
        await this.setProtocol('eth');
        await eventBus.emit('set:asset', {
          asset: asset,
          protocol: 'eth',
          op: 'deposit'
        });
        return;
      }
      /*
      if (this.gateways.bnb[asset]) {
        await this.setProtocol('bnb');
        await eventBus.emit('set:asset', {
          asset: asset,
          protocol: 'bnb',
          op: 'deposit'
        });
        return;
      }
       */
      if (this.gateways.bsc[asset]) {
        await this.setProtocol('bsc');
        await eventBus.emit('set:asset', {
          asset: asset,
          protocol: 'bsc',
          op: 'deposit'
        });
        return;
      }
      if (this.gateways.eos[asset]) {
        await this.setProtocol('eos');
        await eventBus.emit('set:asset', {
          asset: asset,
          protocol: 'eos',
          op: 'deposit'
        });
        return;
      }
      if (this.gateways.waves[asset]) {
        await this.setProtocol('waves');
        await eventBus.emit('set:asset', {
          asset: asset,
          protocol: 'waves',
          op: 'deposit'
        });
        return;
      }
    },
  },
  watch: {
    '$route.params.symbol': {
      handler: async function (asset) {
        this.symbol = asset;
        if (!this.$root.pin) {
          await this.$router.push('/auth-pages/login');
        } else {
          await this.defaultProtocol(asset);
          await this.$store.dispatch('accounts/updateBalances', {
            userId: this.$root.account.account.id,
            lpId: "1.19.181"
          });
          await this.$store.dispatch('wallet/gateways');
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    balanceBts() {
      return this.$store.getters['accounts/portfolio']['1.3.0'] || 0;
    },
    balance() {
      return this.$store.getters['accounts/balances'][this.gateways[this.protocol.type][this.symbol].assetId] || null;
    },
    gateways() {
      return this.$store.getters['wallet/gateways'];
    },
  },
  async created() {
    if (!this.$root.pin) {
      await this.$router.push('/auth-pages/login');
    } else {
      await this.defaultProtocol(this.$route.params.symbol);
    }
    /*
    eventBus.on('set:protocol', async (data) => {
      await this.setProtocol(data);
    });
     */
  }

}
</script>

<style>
.atom-spinner {
  margin-left: auto;
  margin-right: auto;
}
</style>
