<template>
  <div>
    <div v-if="gateways[protocol]" @click="selectShowHide" class="selected-coin p-2 mb-1 border pointer no-select">
      <img class="mb-1"
           :src="'images/assets/' + symbol.replace('XBTSX.','').toLowerCase() + '.png'"/>
      <span class="ml-2">{{ symbol.replace('XBTSX.','') }}</span>
      <i class="float-right" :class="listShow ? 'icon-arrow-up' : 'icon-arrow-down'"></i>
    </div>


    <div v-show="listShow" class="select-coin-container card p-2 no-select">
      <div class="select-coin-filter">
        <div class="w-100">
          <div class="input-group input-group-sm mt-1">
            <div class="input-group-prepend hover-cursor">
                    <span class="input-group-text">
                      <i class="ti-search"></i>
                    </span>
            </div>
            <input
                v-model="coinFilter"
                aria-describedby="search"
                aria-label="search"
                class="form-control text-uppercase"
                placeholder="asset"
                type="text"
            />
          </div>
        </div>
      </div>
      <div v-if="gateways" class="select-coin p-2 m-2">

        <div>
          <div @click="selectAsset(item)"
               class="select-coin-item w-100 pl-3 pb-1 pt-1 border-bottom border-dark" v-for="item in assets"
               v-bind:key="item" v-show="item.includes(coinFilter.toUpperCase())">
            <span><img
                :src="'images/assets/' + item.replace('XBTSX.','').toLowerCase() + '.png'"/> {{
                item.replace('XBTSX.', '')
              }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {xbtsAssets} from '@/config';
import eventBus from '@/plugins/event-bus';

export default {
  name: "SelectCryptoDeposit",
  data() {
    return {
      coinFilter: '',
      listShow: false,
      assets: xbtsAssets,
      protocol: 'coins',
      symbol: 'XBTSX.BTC',
      op: 'deposit'
    }
  },
  computed: {
    gateways() {
      return this.$store.getters['wallet/gateways'];
    },
  },
  methods: {
    async selectShowHide() {
      this.coinFilter = '';
      this.listShow = !this.listShow;
    },
    async selectAsset(asset = 'XBTSX.BTC') {
      this.listShow = false;
      if (this.symbol !== asset) {
        this.symbol = asset;
        await this.$router.push('/wallet/' + this.op + '/' + asset);
      }
    }
  },
  async created() {
    //this.assets.unshift('BTS');
    eventBus.on('set:asset', async (data) => {
      this.symbol = data.asset;
      this.protocol = data.protocol;
      //await this.selectAsset(data.asset, data.protocol);
      this.op = data.op;
    });

    eventBus.on('set:asset:bts-deposit', async () => {
      this.symbol = 'BTS';
      this.op = 'deposit';
    });

    eventBus.on('set:asset:bts-withdraw', async () => {
      this.symbol = 'BTS';
      this.op = 'withdraw';
    });
  }
}
</script>

<style>
.select-coin {
  width: 252px;
  min-width: 252px;
  height: 300px;
  overflow-y: auto;
}

.select-coin img {
  width: 16px;
  height: 16px;
}

.select-coin-item:hover {
  cursor: pointer;
  color: #ffffff;
  background: #1a1f26;
}

.select-coin-container {
  width: 278px;
  min-width: 278px;
  position: absolute;
  z-index: 1000;
}

.pointer:hover {
  cursor: pointer;
}

.selected-coin {
  width: 278px;
  min-width: 278px;
}

.selected-coin img {
  width: 16px;
  height: 16px;
}

</style>
